
.postlist {
    width: 600px;
    padding: 30px;
}
.share-idea {
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    border: 1px solid #D7D7DF;
    align-items: center;
    padding: 17px;
}
.idea-box {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
}
.connection-application{
    border-radius: 17px;
    border: 1px  solid #D7D7DF;
    padding: 15px 15px;
    margin: 20px 0px;
  }
  .nouran-al {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
}
.world-vector {
    display: flex;
    gap: 5px;
    align-items: center;
} 
.world-vector img {
    width: 12px ;
    height: 12px;
}






 /* MOBILE RESPONSIVE */



  @media (max-width:768px) {
    .postlist {
        width: 480px !important;
        padding: 30px 30px;
    }
    .sort{
        width: 320px;
    }
    .solar-permits img{
        width: 438px;
    }
    .robo-solar img {
    width: 438px;
    }
   .robo-solar {
    flex-direction: column;
    }
    } 

    
 @media (max-width:480px) {
    .postlist {
        width: 410px !important;
        padding: 30px 10px;
    }
    .sort{
        width: 200px;
    }
    .solar-permits img{
        width: 380px;
    }
    .robo-solar img {
    width: 380px;
}
.robo-solar {
    flex-direction: column;
}
 }