.helpfaq {
    padding: 31px 0px 30px 30px;
}
.help-accordion {
    margin: 32px 0px 48px 0px;
}

.frequently-question {
    border: 1px solid #D7DFD9;
    border-radius: 18px;
    margin-bottom: 16px;
    padding: 24px;
}
.question {
    
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.answer {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
}
.show {
 margin-top: 14px;
 height: auto;
 max-width: 9999px;
 transition: all 0.5s;

}
.down-arrow {
    border: solid Gray ;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .up-arrow {
    border: solid Gray ;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .contact-us h3 {
  margin-bottom: 32px;
  }
  .contact-us h4 {
    margin-bottom: 12px;
    }
    .sms {
        display: flex;
        gap: 8px;
        align-items: center;
        letter-spacing: 0.5px;
        margin-bottom: 50px;
    }
    .sms svg {
        fill: black;
    }
    .sms p{
        text-decoration-line: underline;
    }
    .sms:hover{
        color: #007560;
    }
    .sms svg:hover{
        fill: #007560;
    }
