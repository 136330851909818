.explorepage {
    width: 570px;
    padding: 30px;
}
.sort-recent {
    display: flex;
    gap: 15px;
   /*  padding: 0px 10px; */
    align-items: center;
    margin-bottom: 24px;
    justify-content: space-between;
}
.sort{
    border-bottom: 1px  solid #D7D7DF;
    width: 440px;
}
.recent {
    display: flex;
    gap: 5px;
}