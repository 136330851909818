.how-it-work {
    display: flex;
    gap: 16px;
    margin-top: 32px;
}
.how-it-count {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.how-it-count h3 {
    background: #C5E4DF;
    border-radius: 50%;
    padding: 15px 21px 15px 20px ;
    font-weight: 700 !important;
    margin-bottom: 22px;
}
.how-it-content h4 {
  font-size: 18px !important;
  font-weight: 700;
}
.how-it-content h6 {
    color: #6F6F6F;
    font-weight: 400;
    line-height: 22px;
}
/* .work-line {
    width: 1px;
    height: 20px;
    background-color:#D7DFD9 ;
    align-items: center;
} */


