.innovators-list {
    width: 380px;
    padding: 30px 0px;
}
.knowledge-corner {
    border-radius: 16px;
    border: 1px solid #D7D7DF;
    padding: 25px 20px;
    margin-bottom: 16px;
}
.knowledge-corner-content {
    display: flex;
    gap: 20px;
    width: 300px;
    padding: 18px 0px;
    line-height: 22px;
}
.share-idea-btn-secondary {
    border-radius: 80px;
    font-size: 16px !important;
    text-decoration: none;
    padding:11px 30px ;
}
.news-all {
    margin-bottom: 16px;
    border-radius: 16px;
}
.news {
    padding-bottom: 10px;
}
.news h4 {
    padding: 19px 20px 10px 20px;
}
.news p {
    font-size: 15px !important;
    margin: 10px 20px;
}
.nov-news {
    display: flex;
    border-bottom: 1px solid #D7D7DF;
    gap: 21px;
    padding: 12px 20px 15px 20px;
}
.top-innovators-all {
    border-radius: 16px;
    padding: 20px 0px;
}
.top-innovators {
    padding: 0px 20px;
    border-bottom: 1px solid #D7D7DF;
}
.top-innovators h4{
    margin-bottom: 10px;
}
.innovators-people {
    display: flex;
    padding: 10px 0px;
}
.steve-maroron {
    display: flex;
    gap: 15px;
    width: 340px;
}
.top-innovators-all p {
    font-size: 15px !important;
    padding: 17px 20px 0px 20px;

}
.follow button  {
    border-radius: 17px;
    padding: 8px 11px;
    border: 1px solid #6F6F6F;
    font-size: 14px !important;
}
.innovation-enablers {
    border-radius: 16px;
    padding: 20px;
    margin: 16px 0px;
}
.future-trends{
    display: flex;
    gap: 11px;
    padding: 20px 0px 0px 0px;
    align-items: center;
}
 .future-img {
    background: #C5E4DF;
    border-radius: 14px;
 }
.future-img img {
    padding: 4px 5px;
 }


/* MOBILE RESPONSIVE */
@media (max-width:480px) {
    .innovators-list {
        display: none;
    }
}
@media (max-width:768px) {
    .innovators-list {
        display: none;
    }
}