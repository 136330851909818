
.similar-innovator {
    width: 380px !important;
    padding: 30px 0px;
}
.related-innovator {
  padding: 20px 18px;
  border-radius: 16px;
  margin-bottom: 16px;
}
.related-innovator h4 {
    margin-bottom:22px ;
}
.related-innovator-people {
    display: flex;
    gap: 16px;
    align-items: center;
    padding-bottom: 15px;
}
.related-topics {
    padding: 20px 18px;
    border-radius: 16px;
    margin-bottom: 16px;
}
.related-innovator button {
    color: #6F6F6F;
    margin-left: 10px;
    margin-bottom: 12px;
    padding: 6px 11px;
    border-radius: 16px;
    border: 1px solid #D7D7DF ;
    font-family: 'Inter', sans-serif;
    font-weight: 500;


}

@media (max-width:480px) {
    .similar-innovator {
        display: none;
    }
}

@media (max-width:768px) {
    .similar-innovator {
        display: none;
    }
}