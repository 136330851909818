.about {
    padding: 31px 0px 30px 30px;
}

.about h2 {
    margin-bottom: 20px;
}

.internal-system p {
    font-size: 17px !important;
    margin-bottom: 44px;
    line-height: 28px !important;
}

.ceo-msg {
    display: flex;
    gap: 15px;
}

.ceo-content h2 {
    margin-bottom: 20px;
}

.ceo-content p {
    font-size: 17px !important;
    width: 705px;
    font-weight: 400;
    line-height: 28px !important;
}

.dewa-launch {
    margin: 28px 0px 0px 0px;
}

.dewa-launch p {
    margin-bottom: 20px;
    font-weight: 400;
    line-height: 28px;
    font-size: 17px !important;
}

.thankyou {
    display: flex;
    align-items: center;
    margin-bottom: 44px;
}
.thankyou img {
    margin-bottom: 20px;
}

.thankyou-content {
    line-height: 28px;
    font-size: 17px !important;
}

.thankyou-content h4 {
    font-weight: 700;
}