

.solar-permits h4 {
    margin-bottom: 12px;
    }
    .solar-permits img{
        width: 538px;
    }
    .implement {
        display: flex;
        justify-content: space-between;
    }
  
  .implement-share {
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: #C5E4DF;
   
      height: 14px;
border-radius: 16px;
    padding: 8px;

  }
  .implement-share h6 {
  font-size: 12px;
  color:  #007560;
  }