.people-data{
    border-radius: 17px;
    border: 1px  solid #D7D7DF;
    padding: 15px 15px;
    margin-bottom: 16px;
  }

  .robo-solar img {
    width: 263px;
    margin-top:16px  ;
}
.robo-solar {
  display: flex;
  gap: 12px;
}