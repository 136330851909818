 .nav{
  padding: 15px 0px;
  border-right: 1px solid #D7D7DF;

 }
  .navbar {
    width: 260px;
    position: sticky;
    overflow: hidden;
  }
 
 ul {
   margin-bottom: 48px;
 }

 ul li {
   list-style: none;
   padding: 13px 0px;
 }

 li :link {
   text-decoration: none;
   text-align: center !important;
   display: flex;
   align-items: center !important;
   gap: 19px;
   font-size: 19px !important;
   font-weight: 400 !important;
 }

 .book {
   margin-left: -3px;
 }

 .nav-item svg {
   fill: black;
 }

 .nav-item {
   color: black;
 }

 .nav-item:hover {
   color: #007560;
 }

 .nav-item:hover svg {
   fill: #007560;
 }

 .share-idea-btn {
   text-decoration: none;
   border: none;
   font-size: 16px !important;
   border-radius: 9999px;
   padding: 15px 50px;
   margin-left: 12px;
 }

 .active {
   color: #007560;
 }

 .active svg {
   fill: #007560;
 }


 
 .nav-icon {
  font-size: 1.8rem;
  cursor: pointer;
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

/* @media (max-width: 480px) {
  
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: absolute;
    left: -110%;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: white;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 90%;
    font-size: 1.8rem;
    cursor: pointer;
    color: black;
  }
} */




@media (max-width:768px) {
  .navbar {
    width: 200px ;
  }
 nav {
  padding-left: 30px;
 }
 .nav-icon {
  display: none;
 }
 .share-idea-btn {
  padding: 15px 20px;
}

  } 

  @media (max-width:480px) {
   nav{
    display: none;
   }
   .nav-icon {
    position: relative;
    display: block;
   }
   .nav {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: absolute;
    left: -200%;
    transition: all 0.5s ease;
  }
  .nav-menu.active {
    background: white;
    left: 100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 10;
  }

  }
