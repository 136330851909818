.drafts-page {
    width: 380px !important;
    padding: 30px 0px;
}
.drafts {
    margin: 24px 0px 16px 0px;
    display: flex;
    padding: 20px 16px;
    border-radius: 16px;
}
.draft-content {
    width: 230px;
}
.mg-seven {
    padding-bottom: 7px;
}