select {
    width: 580px;
    height: 40px;
    background: #F2F2F2;
    border: none;
    border: 1px solid #F2F2F2;
    box-shadow: 0px -1px 0px 0px rgba(34, 34, 34, 0.65) inset;
    margin-bottom: 20px;
    border-top-left-radius: 5px; 
    border-top-right-radius: 5px;
}
option option.label {
    background-color:#F2F2F2;
}
