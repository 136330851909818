.digital {
    width: 600px !important;
    padding: 30px;
}
.idea-arrow {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 20px;
}
.idea-arrow img {
    width: 16px;
    height: 16px;
}
.digitalization h4 {
    margin-bottom: 11px;
}
.under-discus {
    display: flex;
    gap: 10px;
    margin-bottom: 24px;
    align-items: center;
}
.time {
    width: 12px;
    height: 12px;
}
.ellipse {
    width: 4px;
    height: 4px;
}
.comment-vote {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #D7D7DF ;
    border-bottom: 1px solid #D7D7DF ;
    margin-bottom: 36px;
    padding: 12px 0px;
}
.comment-share {
    display: flex;
    gap: 16px;
}
.share-bookmar img {
  padding: 0px 10px ; 
}
.yellow-robo img {
    width: 569px;
    margin-bottom: 31px;
}
.problem-solution {
border-bottom: 1px solid #D7D7DF ;
margin-bottom: 24px;
}
.problem-solution h4 {
 margin-bottom: 24px;
}
.share-thoughts {
    border-radius: 70px;
    padding: 10px 20px;
   /*  padding: 10px 20px; */
    margin: 24px 0px 28px 0px;
}
.bjarni {
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
}
.nakamura {
    display: flex;
    gap: 12px;
    align-items: center;
}
.lorem-content {
border-radius: 6px;
padding: 13px 19px 20px 19px;
margin-bottom: 15px;
}
.replies {
    display: flex;
    justify-content: space-between;
    padding: 0px 11px;
}
.like-reply {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 26px;
}



@media (max-width:768px) {
    .digital {
        width: 480px !important;
        padding: 30px 30px;
    }
    .yellow-robo img {
        width: 480px;
    }
}

@media (max-width:480px) {
    .digital {
        width: 370px !important;
        padding: 30px 10px;
    }
    .yellow-robo img {
        width: 370px !important;
    }
}