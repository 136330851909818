.header{
    border-bottom: 1px solid #D7D7DF;
}

header {
    display: flex;
    justify-content: space-between;
    /* padding: 20px 102px 20px 102px; */
    padding: 20px 0px;
    width: 1238px;
    margin: 0 auto;
}
.logos {
    display: flex;
    gap: 13px;
    align-items: center;
}
.afkari-1 img{
    width: 94px;
    height: 50px;
}
.afkari-3 img{
    height: 41px;
    width: 170px;
}
.right-header {
    display: flex;
    align-items: center !important;
    gap: 16px;
}
.searcch-bar{
    border-radius: 39px;
}
.searcch-bar i{
    padding: 12px;
    font-size: 17px;
}
.access {
    border-radius: 39px;
}
.access img {
    width: 24px;
    height: 22px;
    padding: 7px 9px 6px 9px ;
}
.arabi {
    border-radius: 39px;
}
.arabi img {
    width: 36px;
    height: 20px;
    padding: 10px 17px;
}
.ansari {
    display: flex;
    border-radius: 39px;
    align-items: center !important;
    padding: 10px 12px;
    gap: 10px;
}

.person img {
    width: 27px;
    height: 27px;
}

.vector img {
    width: 9px;
    height: 4px;
    padding-bottom: 3px;
}

  /* MOBILE RESPONSIVE */

  @media (max-width:768px) {
    header {
        width: 680px ;
        padding: 20px 30px;
    }
   
    }
  @media (max-width:480px) {
    header {
        width: 390px;
    }
    .right-header {
        display:none;
    }
    .line{
        display:none;
    } 
    .afkari-1{
        display:none;
    }
  
    }



   