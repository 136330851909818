
.vote-btn {
    color: black;
}
.vote-btn:hover {
    color: #007560;
}
.vote-btn svg {
    fill: white;
}
 
  .vote-btn:hover svg {
    fill: #007560;
  }
  .bookmark-vector svg {
    fill: white;
  }
  .bookmark-vector:hover svg {
    fill: black;
  }
  .vote-box {
    margin-top: 17px;
    border-top:1px  solid #D7D7DF;
    padding: 10px 0px;
    display: flex;
    justify-content: end;
    gap: 16px;
    border-bottom:1px  solid #D7D7DF;

}
.vote-shares {
    padding: 15px 5px 0px 5px;
    display: flex;
    justify-content: space-between;
}
.like-btn{
    display: flex;
    gap: 16px;
    align-items: center !important;

}
.vote-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}