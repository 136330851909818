.idea-form {
    width: 600px;
    padding: 30px;
}

.list-form {
    width: 600px;
}
.idea-title {
    width:570px ;
     border-top-left-radius: 5px; 
     border-top-right-radius: 5px;
     padding: 17px 0px 17px 12px;
}
.idea-description {
    width:570px ;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 13px 0px 83px 12px;
}

input  {
    background: #F2F2F2;
    border: 1px solid #F2F2F2;
    box-shadow: 0px -1px 0px 0px rgba(34, 34, 34, 0.65) inset;
    margin-bottom: 20px;
    border: none;
}

::placeholder {
color: #4F4F4F !important;
font-size: 15px !important;
font-weight: 400 !important;
font-family: 'Roboto', sans-serif !important;

}
.upload {
    border: 1px  dotted #00856F;
    width: 465px;
    margin-top: 16px;
    padding: 19px 59px;
    display: flex;
      flex-direction: column;
    justify-content: center;
    align-items: center;
}
.upload img {
    margin-bottom: 17px;
}
.upload h6 {
    line-height: 24px;
}
.submit {
    text-decoration: none;
    border: none;
    font-size: 16px !important;
    border-radius: 9999px;  
    padding:15px 41px ;
}
.cancel {
    text-decoration: none;
}
.cancel-submit {
    margin-top: 61px;
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: end;
}
.select-innovators h6 {
    font-size: 12px !important;
    color: rgba(34, 34, 34, 0.65);
    margin-bottom: 3px;
    
}
.select-innovators-people {
    display: flex;
    gap: 9px;
}
.choose-innovator-people {
    display: flex;
    gap: 3px;
    align-items: center;
}
.choose-content h5  {
font-size: 14px !important;
}
.choose-content p  {
    font-size: 10px !important;
}
.plus-circle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.plus-circle img {
    margin-right: 10px;
}
.select-innovators  {
    margin-bottom: 24px;
}

