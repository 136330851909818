.main-page {
    width: 1238px ;
    display: flex;
  }
  .routing-page {
    width: 980px ;
  }
  .container {
    width: 1238px ;
    margin: 0 auto;
  }
  .post-page{
    display: flex;
  }

  /* MOBILE RESPONSIVE */
 
 @media (max-width:768px) {
  .post-page {
      flex-direction: column;
  }
  .routing-page {
    width:  500px ;
  }
  .container {
    width: 768px ;
    margin: 0 auto;
  }
  .main-page {
    width: 768px ;
    padding: 0px 0px;

  }
} 

@media (max-width:480px) {

  .post-page {
      flex-direction: column;
  }
  .routing-page {
    width: 480px;
  }
  .container {
    width: 480px ;
    margin: 0 auto;
  }
  .main-page {
    width: 480px;

  }
} 
